import Select, { components, GroupType, OptionProps } from "react-select";
import { useTheme } from "styled-components";
import useLayoutContext from "../../../hooks/useLayoutContext";
import {
  allSeasons,
  groupSeasons,
  isAllSeasons,
  SeasonOptionType,
} from "../util";
import {
  CommonDisplay,
  defaultComponents,
  renderGroup,
  selectStyles,
} from "./common";
import { SearchItem } from "../SearchItem";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";
import { SourceSelection } from "~features/reports/reporting/navigation/useNavigation";
import { Skeleton } from "~components";
import { Box, Text } from "flicket-ui";
import { DATE_FORMAT } from "~lib/helpers/dates/formatDateRange";
import { formatDate, notEmptyPredicate } from "~lib";
import { ReactNode } from "react";

interface SeasonSelectProps {
  onChange: (option: SourceSelection) => void;
  modal?: boolean;
}

export const Option = (props: OptionProps<SeasonOptionType, false>) => {
  return (
    <components.Option {...props}>
      {isAllSeasons(props.data) ? (
        <SearchItem active={props.isFocused}>All seasons</SearchItem>
      ) : (
        <SearchItem active={props.isFocused}>{props.data.name}</SearchItem>
      )}
    </components.Option>
  );
};

function Subtitle() {
  const context = useLayoutContext();

  if (context.pageState === "layoutLoading") {
    return <Skeleton height={15} maxWidth={"400px"} mt={"6/4"} />;
  }

  if (
    context.type !== PRIMARY_NAVIGATION_KEYS.SEASONS ||
    !context.seasonInformation
  )
    return null;

  const date = [
    context.seasonInformation?.startDate,
    context.seasonInformation?.endDate,
  ]
    .filter(notEmptyPredicate)
    .map((d) => formatDate(d, DATE_FORMAT))
    .join(" - ");

  return (
    <Box mt={1}>
      {date && <Text variant="small">{date}</Text>}
      {context.seasonInformation?.location && (
        <Text variant="small">{context.seasonInformation?.location}</Text>
      )}
    </Box>
  );
}

export function SeasonDisplay({ small }: { small?: boolean }) {
  const context = useLayoutContext();

  if (context.type !== PRIMARY_NAVIGATION_KEYS.SEASONS) {
    return null;
  }

  let title: ReactNode;

  if (context.isAllSeasons) {
    title = allSeasons.label;
  } else {
    const seasonId = context.seasonId;
    title =
      context.seasonInformation?.name ??
      context.searchableSeasons.find((s) => s.id === seasonId)?.name;
  }

  return (
    <CommonDisplay
      title={title}
      small={small}
      subtitle={<Subtitle />}
      sourceLabel={context.type}
      image={null}
    />
  );
}

export default function SeasonSelect(props: SeasonSelectProps) {
  const theme = useTheme();
  const context = useLayoutContext();

  const options: (SeasonOptionType | GroupType<SeasonOptionType>)[] = [];

  options.push(allSeasons);
  options.push(...groupSeasons(context.searchableSeasons));

  const onChange = (option: SeasonOptionType) => {
    const selectedSource = isAllSeasons(option)
      ? "all-seasons"
      : { seasonId: option.id };

    props.onChange(selectedSource);
  };

  return (
    <Select
      menuIsOpen
      autoFocus
      options={options}
      getOptionValue={(option: SeasonOptionType) => {
        return option.id;
      }}
      getOptionLabel={(option: SeasonOptionType) => {
        return isAllSeasons(option) ? option.label : option.name;
      }}
      formatGroupLabel={renderGroup}
      onChange={onChange}
      components={{ ...defaultComponents, Option }}
      styles={selectStyles(theme)}
      placeholder="Search seasons"
    />
  );
}
