import useLayoutContext from "~features/reports/reporting/hooks/useLayoutContext";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";
import { useOrganization } from "~hooks";
import { CommonDisplay } from "./common";

export function OverallDisplay({ small }: { small?: boolean }) {
  const context = useLayoutContext();
  const { organization } = useOrganization();

  if (context.type !== PRIMARY_NAVIGATION_KEYS.OVERALL) {
    return null;
  }

  const image = organization.branding?.logo ?? undefined;
  const title = organization.name;

  return (
    <CommonDisplay
      small={small}
      image={image}
      title={title}
      sourceLabel={context.type}
    />
  );
}
