import { CaretDown, MagnifyingGlass } from "@phosphor-icons/react";
import {
  Box,
  Divider,
  Stack,
  Text,
  TextProps,
  useScreenSize,
} from "flicket-ui";
import {
  components,
  ControlProps,
  IndicatorProps,
  SingleValueProps,
  ValueContainerProps,
} from "react-select";
import { CSSProperties, DefaultTheme } from "styled-components";
import { ReportingGroup, ReportingOptionType } from "../util";
import useLayoutContext from "~features/reports/reporting/hooks/useLayoutContext";
import {
  ExtendedFile,
  ReportingFinancialSalesBreakdownSource,
} from "~graphql/typed-document-nodes";
import { ImagePreview } from "~features/EventList/EventListItem";
import React, { MouseEvent } from "react";
import { SelectWithSelectState } from "../../SelectWithSelectedState";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";

export const SingleValue = ({
  ...props
}: SingleValueProps<ReportingOptionType>) => (
  <components.SingleValue {...props}>
    <Text variant="regular" color="N500">
      Search
    </Text>
  </components.SingleValue>
);

export const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<ReportingOptionType, false>) => (
  <Stack alignItems={"center"} px={"6/4"}>
    <MagnifyingGlass size={24} weight="light" />
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  </Stack>
);

export const defaultComponents = {
  DropdownIndicator: null,
  ValueContainer,
  SingleValue,
};

export const defaultSelectProps = {
  menuIsOpen: true,
  autoFocus: true,
};

export const selectStyles = (theme: DefaultTheme) => ({
  container: (baseStyle: CSSProperties): CSSProperties => ({
    ...baseStyle,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  menu: (baseStyle: CSSProperties): CSSProperties => ({
    ...baseStyle,
    position: "relative",
    boxShadow: "none",
    top: 0,
    flex: 1,
    // this defaults to hsl(0, 0%, 100%), which makes the animation looks stutter in the end
    // hence we set it to inherit
    backgroundColor: "inherit",
  }),
  option: (): CSSProperties => ({}),
  group: (baseStyle: CSSProperties): CSSProperties => ({
    ...baseStyle,
    padding: 0,
  }),
  groupHeading: (baseStyle: CSSProperties): CSSProperties => ({
    ...baseStyle,
    padding: 0,
  }),
  valueContainer: (baseStyle: CSSProperties): CSSProperties => ({
    ...baseStyle,
    padding: `7px 8px`,
  }),
  control: (
    baseStyles: CSSProperties,
    state: ControlProps<any, false>
  ): CSSProperties | { [key: string]: CSSProperties } => ({
    ...baseStyles,
    border: `1px solid ${
      state.isFocused ? theme.colors.N500 : theme.colors.N200
    }`,
    "&:hover": {
      border: `1px solid ${theme.colors.N500}`,
    },
    boxShadow: "none",
  }),
});

interface CommonDisplayProps {
  image?: ExtendedFile | null; // null = Remove the image. undefined = use a placeholder image.
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  sourceLabel: string;
  small?: boolean;
}

const SEASON_SOURCE_OPTIONS = [
  {
    label: "Ticket sales",
    value: ReportingFinancialSalesBreakdownSource.Event,
  },
  {
    label: "Membership sales",
    value: ReportingFinancialSalesBreakdownSource.Membership,
  },
];

const SeasonDropdownIndicator = (props: IndicatorProps<any, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown size={20} color="N800" />
    </components.DropdownIndicator>
  );
};

export function CommonDisplay(props: CommonDisplayProps) {
  const { image, title, subtitle, sourceLabel } = props;
  const screenSizes = useScreenSize();
  const context = useLayoutContext();
  const displaySeasonSource = context.type === PRIMARY_NAVIGATION_KEYS.SEASONS;
  const isMobile = screenSizes.isTabletPortraitDown;
  let titleSize: TextProps["variant"] = "header.L";
  if (isMobile) {
    titleSize = "header.M";
  } else if (props.small) {
    titleSize = "header.S";
  }

  return (
    <Stack
      flex={1}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={2}
    >
      <Stack
        justifyContent={"space-between"}
        flexDirection={{ _: "column-reverse", sm: "row" }}
        gap={3}
        flex={1}
      >
        {!isMobile && image !== null && (
          <Box width={{ _: "100%", sm: props.small ? "220px" : "294px" }}>
            <ImagePreview
              isLoading={context.pageState === "layoutLoading"}
              file={image}
            />
          </Box>
        )}
        <Stack direction={"vertical"} flex={1}>
          <Text textTransform={"uppercase"} fontWeight={"bold"} fontSize={1}>
            {sourceLabel}
          </Text>
          <Text variant={titleSize as any}>{title}</Text>
          {subtitle}
          {displaySeasonSource && (
            <Box
              width={"216px"}
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
              }}
            >
              <SelectWithSelectState
                options={SEASON_SOURCE_OPTIONS}
                mt={3}
                width={"216px"}
                menuPortalTarget={document.body}
                onChange={(value: ReportingFinancialSalesBreakdownSource) => {
                  context.setSeasonSource(value);
                }}
                value={SEASON_SOURCE_OPTIONS.find(
                  (item) => item.value === context.seasonSource
                )}
                components={{ DropdownIndicator: SeasonDropdownIndicator }}
              />
            </Box>
          )}
        </Stack>
      </Stack>

      <CaretDown size={20} color="N800" />
    </Stack>
  );
}

export function renderGroup(group: ReportingGroup) {
  return (
    <Box px={1}>
      <Divider my={1} />
      <Text
        variant="small"
        textTransform={"uppercase"}
        fontWeight={"bold"}
        py={1}
      >
        {group.label}
      </Text>
    </Box>
  );
}
