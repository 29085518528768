import { CaretDown } from "@phosphor-icons/react";
import { Box, Text } from "flicket-ui";
import React, { useContext, useState } from "react";
import Select, { components, OptionProps } from "react-select";
import { DefaultTheme, useTheme } from "styled-components";
import { FolderTabs, Icon, Select as StyledSelect } from "~components";
import {
  SourceContext,
  SourceControlContext,
} from "~features/broadcast/context/sourceControl.context";
import { SearchItem } from "~features/reports/reporting/components/SourceSwitcher/SearchItem";
import {
  renderGroup,
  selectStyles,
} from "~features/reports/reporting/components/SourceSwitcher/Select/common";
import {
  DropdownContentProps,
  SourceSwitcherDropdown,
} from "~features/reports/reporting/components/SourceSwitcher/SourceSwitcher";
import {
  groupEvents,
  groupMemberships,
} from "~features/reports/reporting/components/SourceSwitcher/util";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";
import { useReleases } from "~graphql";
import {
  ContentEntityValue,
  InsertLinkModalContentState,
} from "./InsertModal.types";

interface SourceSelectProps {
  searchableMemberships?: SourceContext["reportingSearchIndex"]["searchableMemberships"];
  searchableEvents?: SourceContext["reportingSearchIndex"]["searchableEvents"];
  onChange: (option: ContentEntityValue) => void;
  value: ContentEntityValue;
  theme: DefaultTheme;
}

const SeasonDropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <Box mr={"6px" as any}>
      <CaretDown size={14} color="N800" fontWeight="bold" />
    </Box>
  </components.DropdownIndicator>
);

const Option = (props: OptionProps<ContentEntityValue, false>) => (
  <components.Option {...props}>
    {props.data.name && (
      <SearchItem active={props.isFocused}>{props.data.name}</SearchItem>
    )}
  </components.Option>
);

const SourceSelect = ({
  searchableEvents,
  searchableMemberships,
  onChange,
  theme,
}: SourceSelectProps) => {
  const options = searchableEvents
    ? groupEvents(
        searchableEvents?.map((event) => ({
          type: PRIMARY_NAVIGATION_KEYS.EVENTS,
          ...event,
        })) ?? []
      )
    : groupMemberships(
        searchableMemberships?.map((membership) => ({
          type: PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS,
          ...membership,
        })) ?? []
      );
  const placeholderText = searchableEvents
    ? "Search events"
    : "Search memberships";

  return (
    <Select
      menuIsOpen
      autoFocus
      options={options}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      getOptionValue={(option) => option}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      getOptionLabel={(option) => option.name}
      formatGroupLabel={renderGroup}
      onChange={onChange}
      styles={selectStyles(theme)}
      components={{ Option }}
      placeholder={placeholderText}
    />
  );
};

interface EventMembershipDropDownProps {
  onChange: (args: InsertLinkModalContentState) => void;
  selected: InsertLinkModalContentState;
}
export const createNavTabItem = <T extends "Events" | "Memberships">(
  key: PRIMARY_NAVIGATION_KEYS,
  name: T,
  dataSource: SourceContext["reportingSearchIndex"][`searchable${T}`],
  theme: DefaultTheme,
  onChange: (args: InsertLinkModalContentState) => void,
  selected: InsertLinkModalContentState
) => ({
  key,
  name,
  content: (props: DropdownContentProps) => {
    const stateKey: keyof typeof selected =
      name === "Events" ? "event" : "membership";

    const nullableStateKey = name !== "Events" ? "event" : "membership";

    return (
      <SourceSelect
        theme={theme}
        value={selected[stateKey]}
        onChange={(option) => {
          onChange({
            ...selected,
            [`${stateKey}`]: option,
            [`${nullableStateKey}`]: undefined,
            url: "",
          });
          props.setIsOpen(false);
        }}
        {...{ [`searchable${name}`]: dataSource }}
      />
    );
  },
});

export const createPrimaryNavTabs = ({
  data,
  theme,
  onChange,
  selected,
}: {
  data: SourceContext;
  theme: DefaultTheme;
  onChange: (args: InsertLinkModalContentState) => void;
  selected: InsertLinkModalContentState;
}) => {
  return [
    createNavTabItem(
      PRIMARY_NAVIGATION_KEYS.EVENTS,
      "Events",
      data?.reportingSearchIndex.searchableEvents.map((event) => ({
        ...event,
        name: event.title,
      })),
      theme,
      onChange,
      selected
    ),
    ...(data?.reportingSearchIndex.searchableMemberships.length > 0
      ? [
          createNavTabItem(
            PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS,
            "Memberships",
            data.reportingSearchIndex.searchableMemberships,
            theme,
            onChange,
            selected
          ),
        ]
      : []),
  ];
};

export const EventMembershipDropDown = ({
  onChange,
  selected,
}: EventMembershipDropDownProps) => {
  const data = useContext(SourceControlContext);
  const theme = useTheme();

  const [activeIndex, setActiveIndex] = useState(0);

  const { data: releaseData } = useReleases(selected.event?.id);

  const primaryNavTabs = createPrimaryNavTabs({
    data,
    theme,
    onChange,
    selected,
  });

  return (
    <Box>
      <Text fontSize={"16px" as any} mb={1}>
        Event or Membership
      </Text>
      <SourceSwitcherDropdown
        zIndex={1500}
        isTextButton
        dropdownContent={(props) => (
          <FolderTabs
            activeIndex={activeIndex}
            onTabChange={setActiveIndex}
            items={primaryNavTabs.map((item) => ({
              ...item,
              content: item.content(props),
            }))}
            tabContentWrapperProps={{
              padding: 0,
              borderRadius: "none",
              background: "none",
            }}
          />
        )}
        buttonContent={
          <ButtonContent
            text={selected.event?.name ?? selected.membership?.name ?? null}
          />
        }
      />
      {releaseData?.length > 0 && (
        <>
          <Text fontSize={"16px" as any} mt={4} mb={1}>
            Release
          </Text>
          <StyledSelect
            options={releaseData?.map((release) => ({
              label: release.name,
              value: release.id,
            }))}
            value={{
              label: selected.release?.name,
              value: selected.release?.id,
            }}
            styles={selectStyles(theme)}
            onChange={(option) => {
              const releaseName = releaseData?.find(
                (release) => release.id === option
              )?.name;
              onChange({
                ...selected,
                release: { name: releaseName, id: option },
                urlDisplayText: selected.event?.name,
                url: "",
              });
            }}
            components={{ DropdownIndicator: SeasonDropdownIndicator }}
          />
        </>
      )}
    </Box>
  );
};

const ButtonContent = ({ text }: { text?: string }) => (
  <Box display="flex" width="100%" justifyContent="space-between">
    <Text color={text ? "N800" : "N400"} fontSize={"16px" as any}>
      {text ?? "Select event or membership..."}
    </Text>
    <Icon icon={<CaretDown size={14} weight="bold" />} className="icon" />
  </Box>
);
